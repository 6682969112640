exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-datenschutz-de-js": () => import("./../../../src/pages/datenschutz.de.js" /* webpackChunkName: "component---src-pages-datenschutz-de-js" */),
  "component---src-pages-help-and-contact-en-js": () => import("./../../../src/pages/help-and-contact.en.js" /* webpackChunkName: "component---src-pages-help-and-contact-en-js" */),
  "component---src-pages-hilfe-und-kontakt-de-js": () => import("./../../../src/pages/hilfe-und-kontakt.de.js" /* webpackChunkName: "component---src-pages-hilfe-und-kontakt-de-js" */),
  "component---src-pages-impressum-de-js": () => import("./../../../src/pages/impressum.de.js" /* webpackChunkName: "component---src-pages-impressum-de-js" */),
  "component---src-pages-imprint-en-js": () => import("./../../../src/pages/imprint.en.js" /* webpackChunkName: "component---src-pages-imprint-en-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-membership-en-js": () => import("./../../../src/pages/membership.en.js" /* webpackChunkName: "component---src-pages-membership-en-js" */),
  "component---src-pages-mitgliedschaft-de-js": () => import("./../../../src/pages/mitgliedschaft.de.js" /* webpackChunkName: "component---src-pages-mitgliedschaft-de-js" */),
  "component---src-pages-privacy-policy-en-js": () => import("./../../../src/pages/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-privacy-policy-en-js" */),
  "component---src-pages-publications-en-js": () => import("./../../../src/pages/publications.en.js" /* webpackChunkName: "component---src-pages-publications-en-js" */),
  "component---src-pages-transparency-software-companies-en-js": () => import("./../../../src/pages/transparency-software/companies.en.js" /* webpackChunkName: "component---src-pages-transparency-software-companies-en-js" */),
  "component---src-pages-transparency-software-e-mobilists-en-js": () => import("./../../../src/pages/transparency-software/e-mobilists.en.js" /* webpackChunkName: "component---src-pages-transparency-software-e-mobilists-en-js" */),
  "component---src-pages-transparency-software-versions-en-js": () => import("./../../../src/pages/transparency-software/versions.en.js" /* webpackChunkName: "component---src-pages-transparency-software-versions-en-js" */),
  "component---src-pages-transparenzsoftware-e-mobilist-de-js": () => import("./../../../src/pages/transparenzsoftware/e-mobilist.de.js" /* webpackChunkName: "component---src-pages-transparenzsoftware-e-mobilist-de-js" */),
  "component---src-pages-transparenzsoftware-unternehmen-de-js": () => import("./../../../src/pages/transparenzsoftware/unternehmen.de.js" /* webpackChunkName: "component---src-pages-transparenzsoftware-unternehmen-de-js" */),
  "component---src-pages-transparenzsoftware-versionen-de-js": () => import("./../../../src/pages/transparenzsoftware/versionen.de.js" /* webpackChunkName: "component---src-pages-transparenzsoftware-versionen-de-js" */),
  "component---src-pages-ueber-uns-de-js": () => import("./../../../src/pages/ueber-uns.de.js" /* webpackChunkName: "component---src-pages-ueber-uns-de-js" */),
  "component---src-pages-veroeffentlichungen-de-js": () => import("./../../../src/pages/veroeffentlichungen.de.js" /* webpackChunkName: "component---src-pages-veroeffentlichungen-de-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */)
}

